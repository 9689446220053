@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-3xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}

a {
  @apply underline text-blue-500 hover:text-blue-600
}